import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import MainTemplate from '../../templates/MainTemplate';
import Map from '../../components/Map';
import FilterAttrazioni from '../../api/GetAttrazioni';
import GetCategorie from '../../api/GetCategorie';
import Markdown from 'react-markdown';



const Attrazione = (props) => {

  const { route } = props;
  const idAttrazione = route.params.attrazione;
  const titolo = FilterAttrazioni(route.params.attrazione, "attrazione", "Nome");
  const titoloSottotitoloCategoria = GetCategorie(props, "attrazione", route.params.categoria);
  const titoloCategoria = titoloSottotitoloCategoria && titoloSottotitoloCategoria.length > 0 ? titoloSottotitoloCategoria[0] : '';
  const descrizione = FilterAttrazioni(route.params.attrazione, "attrazione", "Descrizione");
  const descrizioneBreve = FilterAttrazioni(route.params.attrazione, "attrazione", "DescrizioneBreve");
  const coordinate = FilterAttrazioni(route.params.attrazione, "attrazione", "Posizione");
  const percorso = FilterAttrazioni(route.params.attrazione, "attrazione", "Percorso");
  const altre = FilterAttrazioni(route.params.categoria, "attrazione2", route.params.attrazione);

  document.title = titolo+' - Guida Interattiva - Orobie Alps Resort';
  

  const point = coordinate ? {
    id: idAttrazione,
    nome: titolo,
    descrizione: descrizioneBreve,
    point: coordinate
  } : undefined;

  const polygon = percorso ? {
    id: idAttrazione,
    nome: titolo,
    polygon: percorso
  } : undefined;
  
  let points = [];
  if(altre){
    for(let i = 0; i < altre.length; i++){
      points.push({
        id: altre[i][0],
        nome: altre[i][1],
        descrizione: altre[i][2],
        point: altre[i][3]
      })
    }
  }
  
  
  return (
    <MainTemplate {...props} noBorders={true}>

      <Text style={styles.title}>{titolo}</Text>

      <Text style={styles.explain}>Nella mappa interattiva sono presenti tutte le attrazioni per {titoloCategoria}.
      In <Text style={{color:'red'}}>rosso</Text> {titolo}, in <Text style={{color:'green'}}>verde</Text> il resort, in <Text style={{color:'blue'}}>blu</Text> le altre.
      Clicca nella mappa per maggiori informazioni!</Text>
      
      <View style={styles.mapbox}>
        <Map {...props} subjectMarker={point} otherMarkers={points} polygon={polygon} />
      </View>

      <Text style={styles.subtitle}>MAGGIORI INFORMAZIONI</Text>

      <View style={styles.downBox}>
        <Markdown>{descrizione}</Markdown>
      </View>

    </MainTemplate>
  );
  
};

const styles = StyleSheet.create({
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    paddingHorizontal: 20,
    paddingVertical: 10,
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#f0f0f0'
  },
  explain: {
    fontSize: 14,
    padding: 15
  },
  mapbox: {
    width: '100%',
    height: '60%'
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    paddingHorizontal: 20,
    paddingVertical: 10,
    width: '100%',
    textAlign: 'center',
    backgroundColor: '#f0f0f0',
    borderTopColor: 'black',
    borderTopWidth: 3
  },
  downBox: {
    paddingHorizontal: 30,
    paddingVertical: 20,
    backgroundColor: '#ffffff',
    whiteSpace: 'pre-wrap',
    fontFamily: 'BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
  },
});

const points2 = [
  {
    id: "scifondo",
    nome: "Pista sci di Fondo",
    descrizione: "Descrizione di come una pista di sci di fondo possa essere interessante!",
    point: {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "coordinates": [
          9.754162100522398,
          45.95466652994623
        ],
        "type": "Point"
      }
    }
  },
  {
    id: "vialecaduti",
    nome: "Viale dei Caduti",
    descrizione: "Il viale dei Caduti e le sue stranezze molto curiose",
    point: {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "coordinates": [
          9.748162100522398,
          45.95566652994623
        ],
        "type": "Point"
      }
    }
  },
  {
    id: "piana",
    nome: "Strada Piana",
    descrizione: "Scopri perché la strada piana è un percorso rilassante",
    point: {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "coordinates": [
          9.755162100522398,
          45.95686652994623
        ],
        "type": "Point"
      }
    }
  },
]

export default Attrazione;