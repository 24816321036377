import { useEffect, useState } from "react";
import React from 'react';
import GetConstants from "./getConstants.js";

const AMBIENTE = GetConstants('AMBIENTE');
const URL_CATEGORIE = GetConstants('URL_CATEGORIE');
const AUTHORIZATION = GetConstants('AUTHORIZATION');
const parseJSON = (resp) => (resp.json ? resp.json() : resp);
const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }

  return parseJSON(resp).then((resp) => {
    throw resp;
  });
};

function GetCategorie(props, page, parameter) {
  const { navigation } = props;
  const [error, setError] = useState(null);
  const [categorie, setCategorie] = useState([]);

  useEffect(() => {
    const url = URL_CATEGORIE;
    const response = fetch(url, {
      headers: {
        'Authorization': AUTHORIZATION,
      },
    }).then(checkStatus)
      .then(parseJSON)
      .then(({ data }) => setCategorie(data))
      .catch((error) => setError(error));
  }, []);

  if (error) {
    return <div>An error occured: {error.message}</div>;
  }
  if (page == "guida") {
    let categorie_render = []
    {
      categorie.map(({ id, attributes }) => (
        categorie_render.push([attributes.Univoco, attributes.Nome, attributes.BackgroundColor, attributes.Copertina.data != null ? AMBIENTE+attributes.Copertina.data.attributes.url : ''])
      ))
    }
    return categorie_render;
  }
  if (page == "attrazione") {
    for (let i = 0; i < categorie.length; i++) {
      if (categorie[i].attributes.Univoco == parameter) {
        return [categorie[i].attributes.Nome, categorie[i].attributes.Descrizione];
      }
    }
  }
};

export default GetCategorie;