import constants from "../constants.json";

function GetConstant(name){
    if(process.env[name]){
        return process.env[name];
    }
    else if(constants['constants'][0][name]){
        return constants['constants'][0][name];
    }
    return '';
}

export default GetConstant;