import { useEffect, useState } from "react";
import GetConstants from "./getConstants.js";

const parseJSON = (resp) => (resp.json ? resp.json() : resp);

const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }

  return parseJSON(resp).then((resp) => {
    throw resp;
  });
};

const prova = GetConstants();
const URL_CONFIGURAZIONE = GetConstants('URL_CONFIGURAZIONE');
const AUTHORIZATION = GetConstants('AUTHORIZATION');

function GetConfigurazioni (titolo, parameters=[]) {

    const [error, setError] = useState(null);
    const [configurazioni, setConfigurazioni] = useState([]);

    useEffect(() => {
        const url = URL_CONFIGURAZIONE;
        const response = fetch(url, {
            headers: {
                'Authorization': AUTHORIZATION,
            },
        }).then(checkStatus)
        .then(parseJSON)
        .then(({ data }) => setConfigurazioni(data))
        .catch((error) => setError(error));
    }, []);

    if (error) {
        return `An error occured: ${error.message}`;
    }

    if(configurazioni.length==0){
        return "";
    }

    if(titolo=="MessaggioBenvenuto"){
        return Object.values(configurazioni)[1].MessaggioBenvenuto
        .replace("{nome}", parameters[0])
        .replace("{dataInizio}", parameters[1])
        .replace("{dataFine}", parameters[2])
        .replace("{colazione}", parameters[3])
        .replace("{costo}", parameters[4]);
    }
    let title = titolo['titolo'] || titolo;
    if(title in Object.values(configurazioni)[1]){
        return Object.values(configurazioni)[1][title];
    }
    
    return "";
};
export default GetConfigurazioni;