import React from 'react';
import { Pressable, Text, StyleSheet, Image, ImageBackground } from 'react-native';
import MainTemplate from '../../templates/MainTemplate';
import FilterAttrazioni from '../../api/GetAttrazioni';
import GetCategorie from '../../api/GetCategorie';

const Categoria = (props) => {

  const { route, navigation } = props

  const Attrazione = ({id, nome, color, url}) => {
    return (
      <Pressable onPress={() => navigation.navigate("Attrazione", {categoria: route.params.categoria, attrazione: id})}>
        <ImageBackground style={{...styles.attrazioneBox, backgroundColor: color, overflow: 'hidden'}} source={{uri: url}}>
            <Image style={styles.buttonIcon} source={{uri: '/icons8-touch-50.png'}} />
            <Text style={styles.attrazioneText}>{nome}</Text>
        </ImageBackground>
      </Pressable>
    )
  }

  const attrazioni = FilterAttrazioni(route.params.categoria, "categoria", "");
  let attrazioni_render = [];
  if(attrazioni){
    attrazioni.forEach((element) => {
      attrazioni_render.push(<Attrazione key={element[0]} id={element[0]} nome={element[1]} color={element[2]} url={element[3]} />);
    })
  }

  const parametriCategoria = GetCategorie(props, "attrazione", route.params.categoria);
  const titoloCategoria = parametriCategoria && parametriCategoria.length > 0 ? parametriCategoria[0] : '';
  const sottotitoloCategoria = parametriCategoria && parametriCategoria.length > 0 ? parametriCategoria[1] : '';
  document.title = titoloCategoria+' - Guida Interattiva - Orobie Alps Resort';
  return (
    <MainTemplate {...props}>
      <Text style={styles.title}>{titoloCategoria}</Text>
      <Text style={styles.subtitle}>{sottotitoloCategoria}</Text>
      {attrazioni_render}
    </MainTemplate>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 5,
    textTransform: 'capitalize'
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 20,
  },
  attrazioneBox: {
    marginVertical: 10,
    paddingVertical: 25,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 50,
  },
  attrazioneText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 22
  },
  buttonIcon: {
    width: 25,
    height:25,
    marginRight: 10
  },
});

export default Categoria;