import { useEffect, useState } from "react";
import React from 'react';
import GetConstants from "./getConstants.js";

const AMBIENTE = GetConstants('AMBIENTE');
const URL_ATTRAZIONI = GetConstants('URL_ATTRAZIONI');
const AUTHORIZATION = GetConstants('AUTHORIZATION');
const parseJSON = (resp) => (resp.json ? resp.json() : resp);
const checkStatus = (resp) => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }

  return parseJSON(resp).then((resp) => {
    throw resp;
  });
};

function FilterAttrazioni(category, page, parameter){
    const [error, setError] = useState(null);
    const [attrazioni, setAttrazioni] = useState([]);
  
    useEffect(() => {
      const url = URL_ATTRAZIONI;
      const response = fetch(url, {
          headers: {
              'Authorization': AUTHORIZATION,
          },
      }).then(checkStatus)
      .then(parseJSON)
      .then(({ data }) => setAttrazioni(data))
      .catch((error) => setError(error));
    }, []);
  
    if (error) {
      return <div>An error occured: {error.message}</div>;
    }

    if(attrazioni.length==0){
        return;
    }
    if(page=="categoria"){
        let attrazioni_categoria=[]
        {attrazioni.map(({ id, attributes }) => (
            attributes.Categorie.data.forEach((element) => {
                if(element.attributes.Univoco == category){
                    let url = '';
                    try {
                        url = AMBIENTE+attributes.Copertina.data.attributes.url;
                    } catch (error) {
                        url = '';
                    }
                    attrazioni_categoria.push([attributes.Univoco, attributes.Nome, attributes.BackgroundColor, url]);
                }
            })
        ))}

        if(attrazioni_categoria.length==0){
            return;
        }

        return attrazioni_categoria;
    }

    if(page == "attrazione"){
        for(let i = 0; i < attrazioni.length; i++){
            if(attrazioni[i].attributes.Univoco == category){
                return attrazioni[i].attributes[parameter];
            }
        }  
    }  

    if(page=="attrazione2"){
        let altre=[]
        {attrazioni.map(({ id, attributes }) => (
            attributes.Categorie.data.forEach((element) => {
                if(element.attributes.Univoco == category && attributes.Univoco != parameter){
                    altre.push([attributes.Univoco, attributes.Nome, attributes.DescrizioneBreve, attributes.Posizione]);
                }
            })
        ))}
        return altre;
    }
};

export default FilterAttrazioni;