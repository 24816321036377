import React from 'react';
import { View, Text, Image, StyleSheet, Linking, Pressable } from 'react-native';
import MainTemplate from '../../templates/MainTemplate';
import {decode} from 'base-64';
import GetConfigurazioni from '../../api/GetConfigurazioni';
import ReactDOM from 'react-dom'
import ReactMarkdown from 'react-markdown'
import Markdown from 'react-markdown';

const Home = (props) => {
  const { route, navigation } = props
  const { nome, giornoDa, giornoA, costo, colazione } = "data" in route.params?JSON.parse(decodeURIComponent(decode(route.params["data"]))):undefined
  let righeBenvenuto=[];
  const messaggioBenvenutoTest = GetConfigurazioni("MessaggioBenvenuto", [nome, giornoDa, giornoA, costo, colazione]);
  const messaggioBenvenuto = GetConfigurazioni("MessaggioBenvenuto", [nome, giornoDa, giornoA, costo, colazione]).split('\n');
  const messaggioBenvenuto2 = GetConfigurazioni("MessaggioBenvenuto", [nome, giornoDa, giornoA, costo, colazione]);
  if(messaggioBenvenuto.length>1){
    messaggioBenvenuto.forEach(element => {
      righeBenvenuto.push(<Markdown>{element}</Markdown>);
    });
  }
  const scriviciWhatsapp = GetConfigurazioni("ScriviciWhatsapp", []);
  const consultaGuida = GetConfigurazioni("ConsultaGuida", []);
  return (
    <MainTemplate {...props}>
      <Text style={styles.title}>Benvenuto nella tua area riservata Orobie Alps Resort!</Text>
      { nome &&
        <View style={styles.page}>
          <div style={styles.subtitle}>
            <Markdown>{messaggioBenvenutoTest}</Markdown>
          </div>
        </View>
      }
      <Text style={styles.prosegui}>Scegli come proseguire:</Text>
      <View style={styles.buttons}>
        <Pressable style={styles.whatsappButton} onPress={() => Linking.openURL("https://wa.me/39034584035")}>
          <Image style={styles.buttonIcon} source={{uri: '/icons8-whatsapp-50.png'}} />
          <Text style={styles.buttonText}>{scriviciWhatsapp}</Text>
        </Pressable>
        <Pressable style={styles.guidaButton} onPress={() => navigation.navigate("Guida")}>
          <Image style={styles.buttonIcon} source={{uri: '/icons8-map-50.png'}} />
          <Text style={styles.buttonText}>{consultaGuida}</Text>
        </Pressable>
      </View>
    </MainTemplate>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  subtitle: {
    fontSize: 18,
    paddingVertical: 7,
    fontFamily: 'Helvetica',
    whiteSpace: 'pre-wrap'
  },
  bold: {
    fontWeight: 'bold'
  },
  prosegui: {
    fontWeight: 'bold',
    marginTop: 25,
    fontSize: 18,
  },
  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
  },
  whatsappButton: {
    marginVertical: 10,
    marginHorizontal: 10,
    borderRadius: 20,
    backgroundColor: '#25D366',
    padding: 10,
    width: '40%',
    minWidth: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  guidaButton: {
    marginVertical: 10,
    marginHorizontal: 10,
    borderRadius: 20,
    backgroundColor: '#3e8afc',
    padding: 10,
    width: '40%',
    minWidth: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  buttonIcon: {
    width: 25,
    height:25,
    marginRight: 10
  },
  buttonText: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
  }
});

export default Home;