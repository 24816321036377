import React from 'react';
import { Pressable, Text, StyleSheet, Linking, Image, ImageBackground } from 'react-native';
import MainTemplate from '../../templates/MainTemplate';
import GetConfigurazioni from '../../api/GetConfigurazioni';
import GetCategorie from '../../api/GetCategorie';


const Guida = (props) => {

  const { navigation } = props;

  const Categoria = ({id, nome, color, url}) => {
    return (
      <Pressable onPress={() => navigation.navigate("Categoria", {categoria: id})}>
        <ImageBackground style={{...styles.categoriaBox, backgroundColor: color, overflow: 'hidden'}} source={{uri: url}}>
          <Image style={styles.buttonIcon} source={{uri: '/icons8-touch-50.png'}} />
          <Text style={styles.categoriaText}>{nome}</Text>
        </ImageBackground>
      </Pressable>
    )
  }

  const categorie = GetCategorie(navigation, "guida", "");
  let categorie_render = [];
  if(categorie.length > 0){
    categorie.forEach((element) => {
      categorie_render.push(<Categoria key={element[0]} id={element[0]} nome={element[1]} color={element[2]} url={element[3]}/>);
      
    })
  }

  return (
    <MainTemplate {...props}>
      <Text style={styles.title}><GetConfigurazioni titolo="TitoloGuidaCategorie"/></Text>
      <Text style={styles.subtitle}><GetConfigurazioni titolo="DescrizioneGuidaCategorie"/></Text>
      <Pressable style={{...styles.categoriaBox, backgroundColor: '#1D334A', marginBottom: 70}}
          onPress={() => Linking.openURL("https://www.orobiealpsresort.com/faq.htm")}>
        <Image style={styles.buttonIcon} source={{uri: '/icons8-touch-50.png'}} />
        <Text style={styles.categoriaText}><GetConfigurazioni titolo="ReindirizzamentoFAQ"/></Text>
      </Pressable>
      <Text style={styles.title}><GetConfigurazioni titolo="TitoloGuida"/></Text>
      <Text style={styles.subtitle}><GetConfigurazioni titolo="DescrizioneGuida"/></Text>
      {categorie_render}
    </MainTemplate>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 20,
  },
  categoriaBox: {
    marginVertical: 10,
    paddingVertical: 25,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 50,
  },
  categoriaText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 22
  },
  buttonIcon: {
    width: 25,
    height:25,
    marginRight: 10
  },
});

export default Guida;